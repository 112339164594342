// Add url params to string that may or may not already have params
export const addUrlParams = (params: Record<string, string>, url: string) => {
  // If the string passed is not a url, return the string
  // Sometimes we use data uri's instead of valid urls for images
  try {
    const newUrl = new URL(url);
    return new URL(
      `${newUrl.origin}${newUrl.pathname}?${new URLSearchParams([
        ...Array.from(newUrl.searchParams.entries()),
        ...Object.entries(params),
      ])}`,
    ).toString();
  } catch (e) {
    return url;
  }
};
